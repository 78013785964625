/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import { P, A, Image, H2 } from 'components/StyledComponents'
import theme from 'styles/theme'

// Images
import logo from 'img/logo.svg'
import chevronRight from 'img/chevron_right.svg'
import chevronRightWhite from 'img/chevron_right_white.svg'

// Components
import CustomLink from 'components/CustomLink'
import { ContactUs, OpeningTimes } from 'components/ContactInfo/ContactInfo'

// Third Party
import _ from 'lodash'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  height: 41px;
`

const FooterContainer = styled.div`
  ${A} {
    :hover {
      color: ${theme.colorTextContrast};
      font-weight: ${theme.fontWeightXl};
    }
  }
`

const LinkContainer = styled.div`
  a {
    :hover {
      color: ${theme.colorTextContrast};
      font-weight: ${theme.fontWeightXl};

      ${Image} {
        fill: ${theme.colorTextContrast};
      }
    }
  }
`

const SubFooter = styled.div`
  justify-content: space-evenly;
`

const BlueStripe = styled.div`
  border-bottom: 2px solid ${theme.colorMain};
`

const PrivacyContainer = styled.div`
  ${P} {
    opacity: 0.35;
    line-height: 13px;
  }
`

const Footer = ({ subFooter }) => {
  const {
    footer: {
      acf: {
        common_information: commonInformation,
        mijn_auto_heeft: mijnAutoHeeft,
        snelle_links: snelleLinks,
        brands
      }
    }
  } = useStaticQuery(graphql`{
    footer: wordpressWpComponenten(wordpress_id: {eq: 66}) {
      acf {
        common_information {
          email
          phonenumber
          streetname
          zipcode_city
          contact_us {
            icon {
              localFile {
                publicURL
              }
            }
            phonenumber
          }
          opening_times {
            sat
            mon_to_fri
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        mijn_auto_heeft {
          link {
            target
            title
            url
          }
        }
        snelle_links {
          link {
            target
            title
            url
          }
        }
        brands {
          icon {
            localFile {
              publicURL
            }
          }
          link {
            title
            target
            url
          }
        }
      }
    }
  }`)

  return (
    <>
      {subFooter ? (
        <div className="color-background-main">
          <div className="container">
            <SubFooter className="row py-5 d-flex align-items-center color-background-main">
              <CustomLink to="/contact" className="col-12 col-lg-6 py-3 py-lg-0 d-flex justify-content-center">
                <H2 className="text-center color-text-light font-size-xxl font-weight-xl">Neem contact met ons op!</H2>
                <Image className="pl-4" src={chevronRightWhite} alt="" />
              </CustomLink>
              <ContactUs light data={commonInformation.contact_us} className="col-12 col-lg-3 py-3 py-lg-0 d-flex justify-content-center" />
              <OpeningTimes light data={commonInformation.opening_times} className="col-12 col-lg-3 py-3 py-lg-0 d-flex justify-content-center" />
            </SubFooter>
          </div>
        </div>
      ) : (
        <div className="color-background-main empty-space-50" />
      )}
      <FooterContainer className="footer pt-5 pb-5 color-background-secondary">
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 col-lg-4">
              <HeaderContainer className="d-flex align-items-end mb-3">
                <Image height={41} width={260} alt="" src={logo} />
              </HeaderContainer>
              <div>
                <P className="font-size-m">{commonInformation.streetname}</P>
                <P className="font-size-m pb-3">{commonInformation.zipcode_city}</P>
                <P><A className="color-text-contrast font-weight-xl" href={`mailto:${commonInformation.email}`}>{commonInformation.email}</A></P>
                <P><A className="color-text-contrast font-weight-xl" href={`tel:${commonInformation.phonenumber}`}>{commonInformation.phonenumber}</A></P>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <HeaderContainer className="d-flex align-items-end mb-3">
                <H2 className="font-weight-xl font-size-mms color-main">Mijn auto heeft:</H2>
              </HeaderContainer>
              <LinkContainer>
                {_.map(mijnAutoHeeft, (link, index) => (
                  <CustomLink to={link.link.url} key={index} className="d-flex">
                    <Image width={10} height={36} src={chevronRight} alt="" />
                    <P className="font-size-m mb-0 py-1 pl-4">{link.link.title}</P>
                  </CustomLink>
                ))}
              </LinkContainer>
            </div>
            <div className="col-12 col-lg-2 ">
              <HeaderContainer className="d-flex align-items-end mb-3">
                <H2 className="font-weight-xl font-size-mms color-main">Snelle links:</H2>
              </HeaderContainer>
              <LinkContainer>
                {_.map(snelleLinks, (link, index) => (
                  <CustomLink to={link.link.url} key={index} className="d-flex">
                    <Image width={10} height={36} src={chevronRight} alt="" />
                    <P className="font-size-m mb-0 py-1 pl-4">{link.link.title}</P>
                  </CustomLink>
                ))}
              </LinkContainer>
            </div>
            <div className="col-12 col-lg-3">
              <HeaderContainer className="d-flex align-items-end mb-3">
                <H2 className="font-weight-xl font-size-mms color-main" />
              </HeaderContainer>
              <PrivacyContainer className="pb-3 pt-5">
                <CustomLink to="/privacyverklaring"><P className="font-size-m text-right">Privacy Policy</P></CustomLink>
              </PrivacyContainer>
              <BlueStripe className="mb-2" />
              {_.map(brands, (brand, index) => (
                <a href={brand.link.url} key={index} target="_blank" className="px-2 pt-2">
                  <Image loading="lazy" src={brand.icon.localFile.publicURL} height="45" alt="" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </FooterContainer>
    </>
  )
}

export default Footer
