import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = ({ isPartiallyCurrent }) => 
  isPartiallyCurrent ? { 'aria-current': `page` } : null

const CustomLink = ({ to, className, children }) => (
  <Link getProps={isPartiallyActive} to={to} className={className}>
    {children}
  </Link>
)

export default CustomLink
