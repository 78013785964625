const theme = {
  colorTextMain: "#111111",
  colorTextLight: "#FFFFFF",
  colorTextSecondary: "#E0F0F7",
  colorTextContrast: "#FC7E37",
  colorTextStriking: "#005A7C",

  colorMain: "#00A2DF",
  colorLight: "#FFFFFF",
  colorSecondary: "#E0F0F7",
  colorContrast: "#FC7E37",
  colorStriking: "#005A7C",

  fontFamilyMain: 'Jaldi',
  fontFamilySecondary: 'Open Sans',

  fontSizeXXS: 8,
  fontSizeXS: 10,
  fontSizeS: 12,
  fontSizeSM: 14,
  fontSizeM: 16,
  fontSizeMS: 18,
  fontSizeMMS: 20,
  fontSizeXM: 26,
  fontSizeL: 28,
  fontSizeXL: 32,
  fontSizeXXL: 36,
  fontSizeXXML: 45,
  fontSizeXXXL: 75,

  fontWeightXS: 200,
  fontWeightS: 300,
  fontWeightM: 400,
  fontWeightL: 600,
  fontWeightXl: 800,
}

export default theme