import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children, subFooter }) => (
  <>
    <Header />
    <>{children}</>
    <Footer subFooter={subFooter} />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ fontWeight: 800, textAlign: 'center', backgroundColor: '#00A2DF', color: '#FFFFFF'}}
      buttonClasses="d-none"
    >
      We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.
    </CookieConsent>
  </>
)

export default Layout
