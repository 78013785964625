/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

// Images
import imgLogo from 'img/logo.svg'
import facebookIcon from 'img/facebook_white.svg'
import twitterIcon from 'img/twitter_white.svg'
import linkedinIcon from 'img/linkedin_white.svg'
import menuOpen from 'img/menu.svg'
import menuClose from 'img/close.svg'

// Components
import CustomLink from 'components/CustomLink'
import { ContactUs, OpeningTimes, OurAddress } from 'components/ContactInfo/ContactInfo'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import _ from 'lodash'

// Styled Components
import theme from 'styles/theme'
import { P, Image, Button, Ul, Li, CTA } from 'components/StyledComponents'

const Logo = styled(Image)`
  @media (max-width: 1199px) and (min-width: 991px)  {
    width: 220px;
    height: 53px;
  }
  @media (max-width: 429px) {
    width: 250px;
    height: 53px;
  }
  @media (max-width: 330px) {
    width: 200px;
  }
`

const StyledLink = styled(Link)`
  &[aria-current]{
    background-color: ${theme.colorMain};

    @media (max-width: 991px) {
      border-radius: 3px;
    }

    ${P} {
      color: ${theme.colorTextLight};
    }
  }
`

const HeaderRow = styled.div`
  height: ${props => props.height}px;
`

const GlasGarageSpoed = styled.div`
  max-width: 170px;
  ${P} {
    line-height: 0px;
  }
`

const ContactInfoWrapper = styled.div`
  @media (max-width: 1199px) {
    padding-left: 0 !important;
    flex: 0 0 75%;
    max-width: 75%;
  }
`

const LogoWrapper = styled.div`
  @media (max-width: 1199px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true)

  const {
    menu: {
      edges: menuList
    },
    header : {
      acf : {
        common_informatie: {
          our_address: ourAddress,
          contact_us: contactUs,
          opening_times: openingTimes
        },
        cta,
        onlinecta,
        glasgarage,
        social: {
          facebook_link: facebook,
          linkedin_link: linkedin,
          twitter_link: twitter
        }
      }
    }
  } = useStaticQuery(graphql`{
    menu: allWordpressMenusMenusItems {
      edges {
        node {
          items {
            slug
            title
          }
        }
      }
    }
    header: wordpressWpComponenten(wordpress_id: {eq: 31}) {
      acf {
        common_informatie {
          contact_us {
            icon {
              localFile {
                publicURL
              }
            }
            phonenumber
          }
          opening_times {
            sat
            mon_to_fri
            icon {
              localFile {
                publicURL
              }
            }
          }
          our_address {
            streetname
            zipcode_city
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        cta {
          cta_text
          cta_icon {
            localFile {
              publicURL
            }
          }
        }
        onlinecta {
          text
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
        glasgarage {
          text
          phonenumber
          icon {
            localFile {
              publicURL
            }
          }
        }
        social {
          facebook_link
          linkedin_link
          twitter_link
        }
      }
    }
  }`)

  return (
    <nav className="header position-relative">
      <HeaderRow className="d-flex color-background-main" height={50}>
        <div className="container d-flex justify-content-between h-100">
          <div className="d-flex align-items-center">
            <a href={facebook} target="_blank">
              <Image className="mr-2" height={22} width={22} src={facebookIcon} alt="" />
            </a>
            <a href={twitter} target="_blank">
              <Image className="mr-2" height={22} width={22} src={twitterIcon} alt="" />
            </a>
            <a href={linkedin} target="_blank">
              <Image className="mr-2" height={22} width={22} src={linkedinIcon} alt="" />
            </a>
          </div>
          <GlasGarageSpoed className="d-flex align-items-center flex-wrap py-2">
            <P className="m-0 w-100 d-flex justify-content-start align-items-center font-size-sm font-weight-xl color-text-light">{glasgarage.text}</P>
            <P className="m-0 w-100 d-flex justify-content-start align-items-center font-size-ms font-weight-xl">
              <Image height={10} width={10} className="mr-2" src={glasgarage.icon.localFile.publicURL} alt="" />
              <a className="color-text-striking" href={`tel:${glasgarage.phonenumber}`}>{glasgarage.phonenumber}</a>
            </P>
          </GlasGarageSpoed>
        </div>
      </HeaderRow>
      <HeaderRow className="d-flex align-items-center color-background-light" height={120}> 
        <div className="container">
          <div className="row">
            <LogoWrapper className="col-4">
              <Link to="/" className="navbar-item">
                <Logo src={imgLogo} alt="" />
              </Link>
            </LogoWrapper>
            <ContactInfoWrapper className="col-8 pl-3 d-none d-lg-flex justify-content-end">
              <OurAddress data={ourAddress} className="col-4 d-flex justify-content-center" />
              <OpeningTimes data={openingTimes} className="col-4 d-flex justify-content-center" />
              <ContactUs data={contactUs} className="col-4 d-flex justify-content-center" />
            </ContactInfoWrapper>

            <div className="col-8 d-flex justify-content-end">
              <Button height={50} width={50} type="button" onClick={() => setCollapsed(!isCollapsed)} className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-flex d-lg-none`}>
                {isCollapsed ? (
                  <>
                    <Image height={50} width={50} src={menuOpen} alt="" />
                  </>
                    ) : (
                      <Image height={50} width={50} src={menuClose} alt="" />
                    )}
              </Button>
            </div>
            
          </div>
        </div>
      </HeaderRow>
      <div>
        <HeaderMenuMobile items={menuList[0].node.items} isCollapsed={isCollapsed} setCollapse={setCollapsed} />
      </div>

      <HeaderRow className="d-none d-lg-flex align-items-center color-background-secondary" height={55}>
        <div className="container d-flex justify-content-between align-items-center h-100">
          <div className="h-100">
            
            <HeaderMenuDesktop items={menuList[0].node.items} />
          </div>
          <div className="h-100 d-flex">
          <a className="h-100 mr-3" href={onlinecta.url} target="_blank">
            <CTA className="d-flex align-items-center h-100 color-background-contrast">
              <Image src={onlinecta.icon.localFile.publicURL} />
              <P className="font-size-ms font-weight-xl color-text-light">{parse(onlinecta.text)}</P> 
            </CTA>
          </a>
          <CustomLink className="h-100" to="/offerte-aanvragen">
            <CTA className="d-flex align-items-center h-100 color-background-contrast">
              <Image src={cta.cta_icon.localFile.publicURL} />
              <P className="font-size-ms font-weight-xl color-text-light">{parse(cta.cta_text)}</P> 
            </CTA>
          </CustomLink>
          </div>
        </div>
      </HeaderRow>
    </nav>
  )
}

const HeaderMenuMobile = ({ items, isCollapsed }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <div className="navbar-menu">
      <Ul className="w-100 h-100 p-0 pb-5 m-0 d-flex justify-content-center flex-wrap">
        <HeaderMenuItems mobile items={items} />
      </Ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ items }) => (
  <Ul className="w-100 h-100 p-0 d-none d-lg-flex">
    <HeaderMenuItems items={items} />
  </Ul>
)

const HeaderMenuItems = ({ items, mobile }) => (
  <>
    {_.map(items, (item, index) => (
      <Li className={`${mobile ? `w-100 py-2 px-5` : `d-flex justify-content-center align-items-center`}`} key={index}>
        <StyledLink className={`${mobile ? `justify-content-center` : ``} w-100 h-100 py-3 d-flex align-items-center`} to={item.slug === `home` ? `/` : `/${item.slug}`}>
          <P className={`${mobile ? `font-size-xm` : `font-size-m`} px-4  font-weight-m color-text-main`}>{item.title}</P>
        </StyledLink>
      </Li>
    ))}
  </>
)

export default Header
