import React from 'react'
import styled from 'styled-components'

import { P, Image } from 'components/StyledComponents'

const ContactInfo = styled.div`
  ${P} {
    line-height: 18px;
  }
`

export const ContactUs = ({ light, data, className }) => (
  <ContactInfo className={`${className ? `${className}` : ``}`}>
    <div className="col-5 col-lg-2 d-flex justify-content-end justify-content-lg-center align-items-center mr-2">
      <Image height={50} width={50} src={data.icon.localFile.publicURL} alt="" />
    </div>
    <div className="col-7 col-lg-10 d-flex flex-wrap align-items-center justify-content-center">
      <P className={`font-size-sm m-0 text-uppercase w-100 ${light && `color-text-light`}`}>CONTACT OPNEMEN</P>
      <P className={`font-size-sm font-weight-xl text-uppercase m-0 w-100 ${light && `color-text-light`}`}>Bel ons</P>
      <P className={`font-size-sm font-weight-xl text-uppercase m-0 w-100 ${light && `color-text-light`}`}><a className={`${light && `color-text-light`}`} href={`tel:${data.phonenumber}`}>{data.phonenumber}</a></P>
    </div>
  </ContactInfo>
)

export const OpeningTimes = ({ light, data, className }) => (
  <ContactInfo className={`${className ? `${className}` : ``}`}>
    <div className="col-5 col-lg-2 d-flex justify-content-end justify-content-lg-center align-items-center mr-2">
      <Image height={50} width={50} src={data.icon.localFile.publicURL} alt="" />
    </div>
    <div className="col-7 col-lg-10 d-flex flex-wrap align-items-center justify-content-center">
      <P className={`font-size-sm m-0 text-uppercase w-100 ${light && `color-text-light`}`}>OPENINGSTIJDEN</P>
      <P className={`font-size-sm font-weight-xl text-uppercase m-0 w-100 ${light && `color-text-light`}`}>{`MA - VR ${data.mon_to_fri}`}</P>
      <P className={`font-size-sm font-weight-xl text-uppercase m-0 w-100 ${light && `color-text-light`}`}>{`ZA ${data.sat}`}</P>
    </div>
  </ContactInfo>
)

export const OurAddress = ({ data, className }) => (
  <ContactInfo className={`${className ? `${className}` : ``}`}>
    <div className="col-5 col-lg-2 d-flex justify-content-end justify-content-lg-center align-items-center align-items-center mr-2">
      <Image height={50} width={50} src={data.icon.localFile.publicURL} alt="" />
    </div>
    <div className="col-7 col-lg-10 d-flex flex-wrap align-items-center justify-content-center">
      <P className="font-size-sm m-0 text-uppercase w-100">ONS ADRES</P>
      <P className="font-size-sm font-weight-xl text-uppercase m-0 w-100">{data.streetname}</P>
      <P className="font-size-sm font-weight-xl text-uppercase m-0 w-100">{data.zipcode_city}</P>
    </div>
  </ContactInfo>
)

export default ContactInfo